import React from 'react';
import { makeStyles, Theme, createStyles, Box, Link } from '@material-ui/core';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { PORTAL_URL } from 'src/constants/pathConsts';
import { CopilotBrandingIcon } from 'src/components/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 17,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        zIndex: 1, // wihtout this text cannot be hoverable
        cursor: 'pointer',
      },
    },
    logoBox: {
      padding: theme.spacing(1.4, 2),
      zIndex: 1,
      position: 'relative',
      borderRadius: '4px',
      marginTop: theme.spacing(2.5),
      color: GraySmall,
      '& svg': {
        color: GraySmall,
      },
      '&:hover *': {
        color: BlackHeadings,
      },
    },
    badgeLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: GraySmall,
      '&.MuiLink-underlineHover': {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  }),
);
export const AuthScreenPortalBadge = () => {
  const classes = useStyles();

  return (
    <Box className={classes.logoBox}>
      <Link
        href={`${PORTAL_URL}/?utm_medium=powered_by&utm_source=${encodeURIComponent(
          window.location.href,
        )}`}
        target="_blank"
        className={classes.badgeLink}
      >
        <BaseTypography className={classes.root}>Powered by</BaseTypography>
        <CopilotBrandingIcon style={{ height: 'unset', width: 'unset' }} />
      </Link>
    </Box>
  );
};
