import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Auth, I18n } from 'aws-amplify';
import { Box, Theme, makeStyles, createStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Button from 'src/components/Button';
import { AUTH_STATES } from 'src/constants/authConsts';
import history from 'src/history';
import { authScreenStyles } from 'src/components/Auth/styles';
import { FORGOT_PASSWORD_PAGE, REGISTER_PAGE } from 'src/constants';
import { removeSpaceFromString } from 'src/utils/StringUtils';
import {
  ContextProps,
  FlagsContext,
  PortalConfigContext,
  RouteContext,
} from 'src/context';
import AuthContainer from 'src/components/Auth/AuthContainer';
import { BaseTextField } from 'src/components/TextField';
import RowDivider from 'src/components/RowDivider';
import { AuthLinkActionButton } from 'src/components/Auth/AuthLinkActionButton';
import { IAuthenticatorReturn } from 'src/components/AwsAmplify';
import {
  getPreferredUsername,
  GetSignInMetadata,
  GetSignInMetadataInput,
  SignInWithGoogle,
} from 'src/utils/AuthUtils';
import { ClientAuthContainer } from 'src/components/Auth/ClientAuthContainer';
import { GoogleAuthButton } from 'src/components/UI/Buttons/GoogleAuthButton';
import BaseTypography from 'src/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';
import { RootState } from 'src/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      borderRadius: 4,
      padding: 15,
      position: 'absolute',
      top: -32,
      left: 24,
      height: 35,
      width: 35,
    },
    ...authScreenStyles(theme),
  }),
);

const schema = Yup.object().shape({
  username: Yup.string().max(64).required('Email is required'),
  password: Yup.string().max(256).required('Password is required'),
});

const validAuthState = ['signIn', 'signedOut', 'signedUp'];

const Login: FC<IAuthenticatorReturn> = ({
  onStateChange,
  inputs,
  checkContact,
  handleInputChange,
  authState,
}) => {
  const classes = useStyles();
  const { GoogleLoginForClients } = useContext(FlagsContext);

  const [state, setState] = useState({
    isSignInLoading: false,
    email: '',
    password: '',
  });

  const processingCallback = useSelector(
    (s: RootState) => s.auth.processingCallback,
  );

  const context: ContextProps = useContext(RouteContext);
  const portalConfig = useContext(PortalConfigContext);
  const { features, portalHeader } = portalConfig;
  const { signupDisabled } = features;

  useEffect(() => {
    if (context.query && context.query?.email) {
      // login form component can be rendered in `sign_in` or `signed_in` state
      // `signed_in` is rendered when query param has email different than
      // signed_in user. To trigger showComponent below, change state
      onStateChange(AUTH_STATES.SIGN_IN);
      setState((prev) => ({
        ...prev,
        email: decodeURI(context.query?.email ?? ''),
        username: decodeURI(context.query?.email ?? ''),
      }));
    }
  }, []);

  const navigate = (path: string) => {
    history.push(path); // eslint-disable-line
  };

  const handleSignIn = async (event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    // get the username/password typed in by the user or from state
    // the values in state are set from query params on component
    const username = inputs?.username || state?.email || '';
    const password = inputs?.password || state?.password || '';

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported',
      );
    }
    try {
      // to migrate our users from different Cognito pools to user pools
      // we need to send the userPoolId and the webAppClientId
      // to be leveraged by the migration lambda function
      const preferredUsername = getPreferredUsername({
        email: username,
        viewMode: portalConfig.viewMode,
        portalId: portalConfig.portalHeader,
      });
      const user = await Auth.signIn(
        preferredUsername,
        password,
        GetSignInMetadata(context.query as GetSignInMetadataInput),
      );
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        onStateChange('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        onStateChange('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        onStateChange('TOTPSetup', user);
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        onStateChange('customConfirmSignIn', user);
      } else {
        checkContact(user);
      }
    } catch (error) {
      console.error('login failed', error);
      const err = error as any;
      if (err.code === 'UserNotConfirmedException') {
        onStateChange('confirmSignUp', { username });
      } else if (err.code === 'PasswordResetRequiredException') {
        onStateChange('forgotPassword', { username });
      } else {
        throw err;
      }
    }
  };

  const ContainerComponent = GoogleLoginForClients
    ? ClientAuthContainer
    : AuthContainer;

  // The Authenticator component is is a wrapper around all the auth components
  // including this LoginForm and it will render all the components
  // that are passed as children, but we only want to show the login form when the
  // authState is signIn, signedOut, or signedUp and not when login is not processing
  // from callback. The callback is used to handle the oauth sign in flow and complete
  // code/token exchange
  if (processingCallback || !validAuthState.includes(authState)) return null;

  // this is set in the customState for oauth sign in, this state is returned
  // back after a successful login
  // origin is used to redirect the user back to the webapp after login
  // portalId is used to find the SignoutRedirectUrl for the user and redirect
  // the client there after logout
  const googleAuthState = new URLSearchParams({
    portalId: portalHeader,
    origin: window.location.origin,
  });

  return (
    <ContainerComponent>
      {GoogleLoginForClients && !portalConfig.features.disableGoogleSignIn && (
        <>
          <GoogleAuthButton
            onClick={() => SignInWithGoogle(googleAuthState.toString())}
          />
          <RowDivider mt={-1} mb={-0.5}>
            <BaseTypography
              style={{
                color: GraySmall,
              }}
              fontType="12Medium"
            >
              OR
            </BaseTypography>
          </RowDivider>
        </>
      )}

      <Formik
        enableReinitialize
        initialValues={{
          username: state.email,
          password: state.password,
        }}
        validationSchema={schema}
        onSubmit={async (_, { setStatus }) => {
          try {
            setState((prev) => ({ ...prev, isSignInLoading: true }));
            await handleSignIn();
            setState((prev) => ({ ...prev, isSignInLoading: false }));
          } catch (error) {
            setStatus({ success: false });
            setState((prev) => ({ ...prev, isSignInLoading: false }));
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => {
          const isSignInEnabled = values.username && values.password;

          return (
            <form
              noValidate
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <Box className={classes.fields}>
                <BaseTextField
                  sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                  id="login-username"
                  autoFocus={
                    GoogleLoginForClients
                      ? portalConfig.features.disableGoogleSignIn
                      : true
                  }
                  fullWidth
                  type="text"
                  key="username"
                  name="username"
                  variant="outlined"
                  margin="normal"
                  onBlur={handleBlur}
                  onInput={handleChange}
                  onChange={handleInputChange}
                  value={removeSpaceFromString(values.username)}
                  label={I18n.get('Email')}
                  error={Boolean(touched.username && errors.username)}
                  helperText={(touched.username && errors.username) || ' '}
                  autoComplete="off"
                />
                <BaseTextField
                  sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                  id="login-password"
                  fullWidth
                  type="password"
                  key="password"
                  name="password"
                  variant="outlined"
                  onBlur={handleBlur}
                  onInput={handleChange}
                  onChange={handleInputChange}
                  value={values.password}
                  label={
                    <Box
                      flexDirection="col"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {I18n.get('Password')}
                      <AuthLinkActionButton
                        linkText="Forgot password?"
                        onClick={() => navigate(FORGOT_PASSWORD_PAGE)}
                        className={classes.navigationActionsContainerDesktop}
                      />
                      <AuthLinkActionButton
                        linkText="Forgot password?"
                        onClick={() => navigate(FORGOT_PASSWORD_PAGE)}
                        className={classes.clientSignUpLinkMobile}
                      />
                    </Box>
                  }
                  error={Boolean(touched.password && errors.password)}
                  helperText={(touched.password && errors.password) || ' '}
                  autoComplete="off"
                />
              </Box>
              <Box width={1} mt={2}>
                <Button
                  size={GoogleLoginForClients ? 'large' : 'small'}
                  className={cx({
                    [classes.submitButton]: GoogleLoginForClients,
                  })}
                  type="submit"
                  htmlId="login-signIn"
                  color="primary"
                  variant="contained"
                  fullWidth
                  isLoading={state.isSignInLoading}
                  disabled={!isSignInEnabled}
                >
                  {I18n.get('Sign in')}
                </Button>
                {!signupDisabled && (
                  <AuthLinkActionButton
                    linkText="Don't have an account?"
                    onClick={() => navigate(REGISTER_PAGE)}
                    className={classes.navigationActionsContainerMobile}
                  />
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
      {!signupDisabled && (
        <>
          {!GoogleLoginForClients && (
            <RowDivider className={classes.divider} mt={2.5} mb={1.5} />
          )}
          <AuthLinkActionButton
            linkText="Don't have an account?"
            onClick={() => navigate(REGISTER_PAGE)}
            className={classes.navigationActionsContainerDesktop}
          />
        </>
      )}
    </ContainerComponent>
  );
};

export default Login;
