import React, { useContext } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PortalConfigContext } from 'src/context';
import PortalLogo from 'src/components/Layout/PortalLogo';
import BaseTypography from 'src/components/Text/BaseTypography';
import { AuthScreenPortalBadge } from 'src/components/Auth/AuthScreenPortalBadge';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import PaymentsClient from 'src/clients/PaymentsClient';
import { canDisablePortalBadge } from 'src/utils/PlansUtils';
import PortalFullLogo from 'src/components/Layout/PortalFullLogo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: 450,
      margin: 'auto',
    },
  },
  card: {
    boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px',
    height: '500px',
    width: '1000px',
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& > *': {
      // child containers should take
      // up 50% of the card each
      flex: '1 1 50%',
    },
  },
  content: {
    boxSizing: 'border-box',
    padding: theme.spacing(7, 7.5, 3, 7.5),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(15, 4, 0, 4),
    },
  },
  media: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  descriptionContainer: {
    textAlign: 'center',
  },
}));

interface AuthContainerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

const AuthContainer: React.FC<AuthContainerProps> = ({
  title,
  description,
  children,
}) => {
  const portalConfig = useContext(PortalConfigContext);

  const [shouldShowPortalBadge, setShouldShowPortalBadge] =
    React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const portalIconWrapperMarginBottom = React.useMemo(
    () => (isMobileScreen ? 7 : 4),
    [isMobileScreen],
  );

  /**
   * This method check for portal plan status. It call
   * get portal plan api which return if the portal is in pro
   * plan or not. And then, it updates the portal badge render condition
   * When it is a pro plan, get show badge flag prop from
   * the portal config, otherwise it should be explicitly shown.
   */
  const checkPortalStatus = async () => {
    const portalPlanStatusData = await PaymentsClient.getPortalPlan();
    const { isPro, isLegacyPaidPlan, planType } = portalPlanStatusData;
    const canDisableBadge = canDisablePortalBadge({
      isPro,
      isLegacyPaidPlan,
      planType,
    });
    setShouldShowPortalBadge(
      canDisableBadge ? !portalConfig.MarketingSite.disableBadge : true,
    );
  };

  React.useEffect(() => {
    checkPortalStatus();
  }, []);

  /**
   * This function provides the auth image cached url.
   * @returns auth image cdn path
   */
  const getAuthImageSrc = () => {
    const authImgS3Path = portalConfig.assets.authImage.signInUrl || '';
    return getPublicImageURL(authImgS3Path, {
      resize: {
        width: 500,
      },
    });
  };

  // we need to show full logo when internal user have uploaded a file for full logo
  const showFullLogo =
    Boolean(portalConfig.assets.logo.logoImageUrl) &&
    // dummy image is used for default state (equivalent to no image uploaded)
    !portalConfig.assets.logo.logoImageUrl.includes('dummyimage');

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Box
            className={classes.logoWrapper}
            mb={title ? 0 : portalIconWrapperMarginBottom}
          >
            {showFullLogo ? <PortalFullLogo logoHeight={60} /> : <PortalLogo />}
          </Box>
          {title && (
            <Box
              width={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
              my={isMobileScreen ? 3 : 1.5}
            >
              <BaseTypography fontType="18Medium">{title}</BaseTypography>
              {description && (
                <BaseTypography
                  fontType="13Regular"
                  className={classes.descriptionContainer}
                >
                  {description}
                </BaseTypography>
              )}
            </Box>
          )}

          {children}
          {isMobileScreen && shouldShowPortalBadge ? (
            <AuthScreenPortalBadge />
          ) : null}
        </CardContent>
        <CardMedia
          className={classes.media}
          image={getAuthImageSrc()}
          title="Cover"
        />
      </Card>
      {!isMobileScreen && shouldShowPortalBadge ? (
        <AuthScreenPortalBadge />
      ) : null}
    </Box>
  );
};

export default AuthContainer;
