import React from 'react';
import { Container, makeStyles, createStyles } from '@material-ui/core';
import cx from 'clsx';
import * as Colors from 'src/theme/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100%',
      fontFamily: ['"Inter"', '"Helvetica"', '"Arial"', 'san-serif'].join(','),
      backgroundColor: Colors.white,
      ['@media screen and (min-height: 700px)']: {
        alignItems: 'center',
      },
    },
    greyBackground: {
      backgroundColor: Colors.HoverBackground,
    },
    container: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        height: '100%',
      },
    },
  }),
);

interface LoginPageLayoutProps {
  useGreyBackground?: boolean;
}

export const LoginPageLayout: React.FC<LoginPageLayoutProps> = ({
  useGreyBackground,
  children,
}) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.body, {
        [classes.greyBackground]: useGreyBackground,
      })}
    >
      <Container className={classes.container} data-test-id="login-page-layout">
        {/* This is gross and ugly but the recommended approach for handling the TS error
         of React.Node is not assignable to NonNullable.. coming from MUI Container
         https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051#issuecomment-449628575 */}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{children}</>
      </Container>
    </div>
  );
};
