import React, { FC, useEffect, useState, useContext } from 'react';
import { Auth, I18n } from 'aws-amplify';
import { Box, Theme, makeStyles, createStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { authScreenStyles } from 'src/components/Auth/styles';
import Button from 'src/components/Button';
import {
  ContextProps,
  FlagsContext,
  PortalConfigContext,
  RouteContext,
} from 'src/context';
import { AUTH_STATES } from 'src/constants/authConsts';
import AuthContainer from 'src/components/Auth/AuthContainer';
import { BaseTextField, PasswordField } from 'src/components/TextField';
import { IAuthenticatorReturn } from 'src/components/AwsAmplify';
import { objectWithProperties } from 'src/components/AwsAmplify/common/constants';
import { getPreferredUsername, SignInWithGoogle } from 'src/utils/AuthUtils';
import { ClientAuthContainer } from 'src/components/Auth/ClientAuthContainer';
import { GoogleAuthButton } from 'src/components/UI/Buttons/GoogleAuthButton';
import MemoBaseTypography from 'src/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';
import RowDivider from 'src/components/RowDivider';
import { AuthLinkActionButton } from '../AuthLinkActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...authScreenStyles(theme),
  }),
);

const schema = Yup.object().shape({
  password: Yup.string()
    .max(256, 'Password must be less than 256 characters')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must have at least one lowercase char')
    .matches(/[A-Z]/, 'Password must have at least one uppercase char')
    .matches(/[0-9]+/, 'Password must have at least one number')
    .required('Password is required'),
});

interface IState {
  isSignInLoading?: boolean;
  errorMessage?: string;
}

const validAuthStates = ['requireNewPassword'];

const RequireNewPasswordForm: FC<IAuthenticatorReturn> = ({
  handleInputChange,
  authData,
  inputs,
  checkContact,
  authState,
  onStateChange,
}) => {
  const context: ContextProps = useContext(RouteContext);
  const portalConfig = useContext(PortalConfigContext);

  const classes = useStyles();

  // get onboarding user credentials from session storage
  // in order to use them for auto sign in
  const onboardingCredentials =
    typeof window !== 'undefined'
      ? JSON.parse(
          window.sessionStorage.getItem('onboarding_credentials') || '{}',
        )
      : null;

  /**
   * Get the initial email state which will be used to auto sign in the user
   * if both email and password are present on mount.
   */
  const [initialEmail] = useState(
    authData?.challengeParam?.userAttributes?.email ||
      context?.query?.email ||
      sessionStorage.getItem('email') ||
      onboardingCredentials.email ||
      '',
  );

  const [isConfirmNewPasswordLoading, setIsConfirmNewPasswordLoading] =
    useState(false);

  const [state, setState] = useState<IState>({
    isSignInLoading: false,
    errorMessage: 'The entered passwords do not match',
  });

  const autoSignIn = async (userEmail: string, password: string) => {
    try {
      setState({
        isSignInLoading: true,
      });
      const username = getPreferredUsername({
        email: userEmail,
        viewMode: portalConfig.viewMode,
        portalId: portalConfig.portalHeader,
      });
      const user = await Auth.signIn(username, password, {
        portal: portalConfig.portalHeader,
      });

      // remove onboarding credentials from session storage after login
      // this does nothing if the credentials are not present from the onboarding flow
      window.sessionStorage.removeItem('onboarding_credentials');

      setState({
        isSignInLoading: false,
      });
      if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        onStateChange(AUTH_STATES.REQUIRE_NEW_PASSWORD, user);
      } else if (user && user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        onStateChange(AUTH_STATES.CONFIRM_SIGN_IN, user);
      } else {
        onStateChange(AUTH_STATES.SIGN_IN);
      }
    } catch (e) {
      setState({
        isSignInLoading: false,
      });
    }
  };

  // if we have email and password in query or session, auto sign in user
  // Need to execute this only on mount to reduce component re renders
  useEffect(() => {
    const password =
      context?.query?.password ||
      context?.query?.auth ||
      sessionStorage.getItem('password') ||
      onboardingCredentials.password;
    if (initialEmail && password) autoSignIn(initialEmail, password);
  }, []);

  const handleActivateAccount = async () => {
    const user = authData;

    try {
      if (!Auth || typeof Auth.completeNewPassword !== 'function') {
        throw new Error(
          'No Auth module found, please ensure @aws-amplify/auth is imported',
        );
      }
      const { requiredAttributes } = user.challengeParam;
      const attrs = objectWithProperties(inputs, requiredAttributes);
      const { password } = inputs;

      if (!user || !password) return;

      const res = await Auth.completeNewPassword(user, password, attrs);
      if (res?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        onStateChange('confirmSignIn', res);
      } else if (res?.challengeName === 'MFA_SETUP') {
        onStateChange('TOTPSetup', res);
      } else {
        checkContact(res);
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error?.message || 'Unexpected Error');
      }
    }
  };

  const { GoogleLoginForClients } = useContext(FlagsContext);
  const Container = GoogleLoginForClients ? ClientAuthContainer : AuthContainer;

  if (!validAuthStates.includes(authState)) return null;

  const googleAuthState = new URLSearchParams({
    portalId: portalConfig.portalHeader,
    // in case of error, we want to bring the user back to the activate page
    // to do this, we set the origin to be the full url
    origin: window.location.href,
    invitedEmail: initialEmail,
  });

  return (
    <Container title={!GoogleLoginForClients ? 'Activate your account' : ''}>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={schema}
        onSubmit={async (_, { setStatus, setErrors }) => {
          try {
            setIsConfirmNewPasswordLoading(true);
            await handleActivateAccount();
            setIsConfirmNewPasswordLoading(false);
          } catch (error) {
            setStatus({ success: false });
            setErrors({
              password: state.errorMessage,
            });
            setIsConfirmNewPasswordLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          touched,
          handleChange,
          values,
        }) => {
          const isDisabledSend =
            state.isSignInLoading || !values.password || errors.password;
          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={classes.formContainer}
            >
              <Box className={classes.fields}>
                {GoogleLoginForClients &&
                  !portalConfig.features.disableGoogleSignIn && (
                    <>
                      <GoogleAuthButton
                        onClick={() =>
                          SignInWithGoogle(googleAuthState.toString())
                        }
                      />
                      <RowDivider mt={0} mb={0}>
                        <MemoBaseTypography
                          style={{
                            color: GraySmall,
                          }}
                          fontType="12Medium"
                        >
                          OR
                        </MemoBaseTypography>
                      </RowDivider>
                    </>
                  )}
                <BaseTextField
                  sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                  fullWidth
                  variant="outlined"
                  type="text"
                  key="username"
                  name="username"
                  label={I18n.get('Email')}
                  value={initialEmail}
                  helperText={' '}
                  disabled
                />
                <PasswordField
                  sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
                  autoFocus
                  fullWidth
                  type="password"
                  key="password"
                  name="password"
                  variant="outlined"
                  onBlur={handleBlur}
                  onInput={handleChange}
                  onChange={handleInputChange}
                  label={I18n.get('Set a password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={(touched.password && errors.password) || ' '}
                  autoComplete="off"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </Box>

              <Box mt={3} width={1}>
                <Button
                  fullWidth
                  size="large"
                  className={clsx({
                    [classes.submitButton]: GoogleLoginForClients,
                  })}
                  htmlId="change-button"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!!isDisabledSend}
                  isLoading={isConfirmNewPasswordLoading}
                >
                  {I18n.get('Activate account')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>

      <AuthLinkActionButton
        linkText="Already have an account?"
        onClick={() => {
          // clear the query params (email & auth)
          // which are used to render the activate account form.
          // this would not re-render the form, so we need to reload the page.
          window.location.href = window.location.pathname;
        }}
        className={classes.navigationActionsContainerDesktop}
      />
    </Container>
  );
};

export default RequireNewPasswordForm;
