import React, { useContext, useEffect } from 'react';
import { PortalConfigContext } from 'src/context';
import PortalLogo from 'src/components/Layout/PortalLogo';
import { AuthScreenPortalBadge } from 'src/components/Auth/AuthScreenPortalBadge';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import PaymentsClient from 'src/clients/PaymentsClient';
import { canDisablePortalBadge } from 'src/utils/PlansUtils';
import PortalFullLogo from 'src/components/Layout/PortalFullLogo';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import BaseTypography from 'src/components/Text/BaseTypography';

const GOOGLE_AUTH_CARD_HEIGHT = 580;
const AUTH_CARD_HEIGHT = 500;

interface AuthContainerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export const ClientAuthContainer: React.FC<AuthContainerProps> = ({
  title,
  description,
  children,
}) => {
  const portalConfig = useContext(PortalConfigContext);
  const [shouldShowPortalBadge, setShouldShowPortalBadge] =
    React.useState(false);
  const googleAuthEnabled = !portalConfig.features.disableGoogleSignIn;
  const isMobileScreen = useMediaQuery('sm', 'max');
  const hasBannerImage = useMediaQuery('xl', 'min');

  /**
   * This method check for portal plan status. It call
   * get portal plan api which return if the portal is in pro
   * plan or not. And then, it updates the portal badge render condition
   * When it is a pro plan, get show badge flag prop from
   * the portal config, otherwise it should be explicitly shown.
   */
  const checkPortalStatus = async () => {
    const portalPlanStatusData = await PaymentsClient.getPortalPlan();
    const { isPro, isLegacyPaidPlan, planType } = portalPlanStatusData;
    const canDisableBadge = canDisablePortalBadge({
      isPro,
      isLegacyPaidPlan,
      planType,
    });
    setShouldShowPortalBadge(
      canDisableBadge ? !portalConfig.MarketingSite.disableBadge : true,
    );
  };

  React.useEffect(() => {
    checkPortalStatus();
  }, []);

  // the image height and width depends on whether google auth is enabled or not.
  const coverImageSize = portalConfig.features.disableGoogleSignIn
    ? AUTH_CARD_HEIGHT
    : GOOGLE_AUTH_CARD_HEIGHT;
  /**
   * This function provides the auth image cached url.
   * @returns auth image cdn path
   */
  const getAuthImageSrc = () => {
    const devicePixelRatio =
      typeof window === 'undefined' ? 1 : window.devicePixelRatio;
    // Cap the devicePixelRatio support at 2.
    const pxRatioMultiplier = Math.min(devicePixelRatio, 2);
    const authImgS3Path = portalConfig.assets.authImage.signInUrl || '';
    return getPublicImageURL(authImgS3Path, {
      resize: {
        width: coverImageSize * pxRatioMultiplier,
        height: coverImageSize * pxRatioMultiplier,
      },
    });
  };

  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imageSrc = getAuthImageSrc();
  useEffect(() => {
    if (!hasBannerImage) return;

    const image = new Image();
    image.src = imageSrc;

    const handleImageLoaded = () => {
      setIsImageLoaded(true);
    };
    image.addEventListener('load', handleImageLoaded);

    return () => {
      image.removeEventListener('load', handleImageLoaded);
    };
  }, [getAuthImageSrc]);

  // we need to show full logo when internal user have uploaded a file for full logo
  const showFullLogo =
    Boolean(portalConfig.assets.logo.logoImageUrl) &&
    // dummy image is used for default state (equivalent to no image uploaded)
    !portalConfig.assets.logo.logoImageUrl.includes('dummyimage');

  return (
    <div
      className={[
        'h-full',
        'flex',
        'items-center',
        'justify-center',
        'flex-col',
        'm-0',
        'm-auto',
        'sm:max-w-lg',
        'xl:max-w-6xl',
      ].join(' ')}
    >
      <div
        className={[
          'drop-shadow-sm',
          'rounded-lg',
          'bg-white',
          'flex',
          'relative',
          'overflow-hidden',
          'h-full',
          'w-full',
          'min-w-96',
          googleAuthEnabled ? 'xl:w-[1120px]' : 'xl:w-[1000px]',
          googleAuthEnabled ? 'xl:h-[580px]' : 'xl:h-[500px]',
        ].join(' ')}
      >
        <div
          className={[
            'flex',
            'w-full',
            'flex-col',
            'h-full',
            'xl:w-1/2',
            'pt-16',
            'px-16',
            'pb-12',
            'box-border',
            '[&>form]:grow-0',
          ].join(' ')}
        >
          <div
            className={[
              'flex',
              'justify-center',
              'items-center',
              'w-full',
              'mx-auto',
              title ? 'mb-0' : 'mb-10',
            ].join(' ')}
          >
            {showFullLogo ? <PortalFullLogo logoHeight={60} /> : <PortalLogo />}
          </div>
          {title && (
            <div
              className={[
                'w-full',
                'flex',
                'items-center',
                'flex-col',
                'mt-4',
                'mx-0',
                'mb-10',
                'gap-2',
              ].join(' ')}
            >
              <BaseTypography fontType="18Medium">{title}</BaseTypography>
              {description && (
                <BaseTypography className="text-slate-500" fontType="13Regular">
                  {description}
                </BaseTypography>
              )}
            </div>
          )}

          {children}
          {isMobileScreen && shouldShowPortalBadge ? (
            <AuthScreenPortalBadge />
          ) : null}
        </div>
        <div
          className={[
            'hidden',
            'xl:flex',
            'xl:w-1/2',
            'bg-cover',
            'bg-center',
            'text-white',
            'flex-col',
            'justify-end',
            'transition-opacity',
            'border-r-8',
            isImageLoaded ? 'opacity-100' : 'opacity-0',
          ].join(' ')}
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
        />
      </div>
      <div className="min-h-20">
        {!isMobileScreen && shouldShowPortalBadge ? (
          <AuthScreenPortalBadge />
        ) : null}
      </div>
    </div>
  );
};
