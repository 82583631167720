import React from 'react';
import { ClientLogin } from 'src/routes/auth/ClientLogin';
import { OnboardingLoginPage } from 'src/components/Onboarding/Login';
import { PortalConfig } from 'src/context';

type authActionContextType = {
  query: {
    step?: string;
  };
  portalConfig: PortalConfig;
};

function action(context: authActionContextType) {
  const { query } = context;
  const isInternalUser = context.portalConfig.viewMode === 'internal';
  const { title, description, socialShareImage } =
    query?.step === 'forgotPassword'
      ? {
          title: 'Copilot • Forgot Password',
          description:
            'Recover your password to log back in to your Copilot account. ',
          socialShareImage: '/images/copilot_social_image.jpg',
        }
      : {
          title: 'Copilot • Log in',
          description:
            'Log in to the Copilot Dashboard to manage your business.',
          socialShareImage: '/images/copilot_social_image.jpg',
        };
  return {
    chunks: ['login'],
    ...(isInternalUser ? { title, description, socialShareImage } : {}),
    component: isInternalUser ? <OnboardingLoginPage /> : <ClientLogin />,
  };
}

export default action;
