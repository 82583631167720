import { Link } from '@material-ui/core';
import React, { useContext } from 'react';
import { PortalConfigContext } from 'src/context';
import { getPublicImageURL } from 'src/utils/ImageUtils';

interface StyleProps {
  logoHeight?: number;
}
interface PortalFullLogoProps extends StyleProps {
  isPreviewLogo?: boolean;
  previewFullLogoUrl?: string;
}

const PortalFullLogo = ({
  isPreviewLogo = false,
  previewFullLogoUrl,
  logoHeight = 100,
}: PortalFullLogoProps) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const portalConfig = useContext(PortalConfigContext);

  const logoPath = React.useMemo(() => {
    // for preview logo use the image url passed down in props
    // else use logoImageUrl from portalconfig
    const url = isPreviewLogo
      ? previewFullLogoUrl
      : portalConfig.assets.logo.logoImageUrl;
    if (url)
      return getPublicImageURL(url, {
        resize: {
          height: 100,
        },
      });

    return '';
  }, [isPreviewLogo, previewFullLogoUrl, portalConfig]);

  return (
    <Link href="/" className="leading-0 hover:no-underline">
      <div
        className={`rounded-s-4 flex object-contain transition justify-center ${
          isImageLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ height: logoHeight }}
      >
        <img
          alt={portalConfig.metadata.title || portalConfig.name || 'Logo'}
          src={logoPath}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
        />
      </div>
    </Link>
  );
};

export default PortalFullLogo;
